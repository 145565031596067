import * as React from 'react';

import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';

import { AbstractMesh, Animation, AnimationGroup, ArcRotateCamera, CubeTexture, EasingFunction, Mesh, Scene, SceneLoader, SineEase, Vector3 } from "@babylonjs/core";
import SceneComponent from 'babylonjs-hook';
import './App.css';

if (process.env.NODE_ENV !== 'production') {
  require("@babylonjs/core/Debug/debugLayer");
  require("@babylonjs/inspector");
}

enum Size {
  BQ,
  NQ,
  HQ,
}

interface IState {
  loading: boolean;
  size: Size;
  assemble: boolean;
  rotate: boolean;
  environment: boolean;
}

class App extends React.Component<{}, IState> {
  scene?: Scene;
  camera?: ArcRotateCamera;
  skybox?: Mesh;
  convertDownBQ?: AbstractMesh;
  convertUpNQ?: AbstractMesh;
  convertDownNQ?: AbstractMesh;
  convertUpHQ?: AbstractMesh;
  convertDownHQ?: AbstractMesh;
  convertAnimation?: AnimationGroup;

  state: IState = {
    loading: true,
    size: Size.NQ,
    assemble: true,
    rotate: true,
    environment: true,
  }

  componentWillUpdate(nextProps: Readonly<{}>, nextState: Readonly<IState>): void {
    if (nextState.size !== this.state.size)
      this.setSize(nextState.size)
    if (nextState.assemble !== this.state.assemble)
      this.setAssemble(nextState.assemble)
    if (nextState.rotate !== this.state.rotate)
      this.setRotate(nextState.rotate)
    if (nextState.environment !== this.state.environment)
      this.setEnvironment(nextState.environment)
  }

  onSceneReady = async (scene: Scene) => {
    this.setState({ loading: true })
    this.scene = scene

    // 摄像机
    this.camera = new ArcRotateCamera("camera", 0, Math.PI / 2, 5, Vector3.Zero(), scene);
    this.camera.lowerRadiusLimit = 2;
    this.camera.upperRadiusLimit = 10;
    this.camera.attachControl(scene.getEngine().getRenderingCanvas(), true);
    this.camera.wheelPrecision = 50
    this.camera.useNaturalPinchZoom = true

    // 环境贴图
    scene.environmentTexture = CubeTexture.CreateFromPrefilteredData('/model/Runyon_Canyon_A_2k_cube_specular.dds', scene);
    this.skybox = scene.createDefaultSkybox(scene.environmentTexture, false, 1000, 0, false)!;

    // 载入模型
    await SceneLoader.ImportMeshAsync(['ConvertDownBQ', 'ConvertUpNQ', 'ConvertDownNQ', 'ConvertUpHQ', 'ConvertDownHQ', 'Up', 'AntennaShield', 'Down', 'Outer'], '/model/all.babylon', undefined, scene);
    this.convertDownBQ = scene.getMeshById('ConvertDownBQ')!;
    this.convertUpNQ = scene.getMeshById('ConvertUpNQ')!;
    this.convertDownNQ = scene.getMeshById('ConvertDownNQ')!;
    this.convertUpHQ = scene.getMeshById('ConvertUpHQ')!;
    this.convertDownHQ = scene.getMeshById('ConvertDownHQ')!;

    // 设置模型
    let convertUp = new AbstractMesh("ConvertUp", scene)
    let convertDown = new AbstractMesh("ConvertDown", scene)
    this.convertDownBQ.parent = convertDown
    this.convertUpNQ.parent = convertUp
    this.convertDownNQ.parent = convertDown
    this.convertUpHQ.parent = convertUp
    this.convertDownHQ.parent = convertDown

    // 动画
    let circleEase = new SineEase()
    circleEase.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    const convertUpSlide = new Animation("ConvertUpSlide", "position.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    convertUpSlide.setKeys([
      { frame: 0, value: 0 },
      { frame: 80, value: .16 },
      { frame: 100, value: .5 },
    ]);
    convertUpSlide.setEasingFunction(circleEase)
    const convertUpRotate = new Animation("ConvertUpRotate", "rotation.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    convertUpRotate.setKeys([
      { frame: 0, value: 0 },
      { frame: 80, value: -Math.PI * 10 },
      { frame: 100, value: -Math.PI * 9.9 },
    ]);
    convertUpRotate.setEasingFunction(circleEase)
    const convertDownSlide = new Animation("ConvertDownSlide", "position.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    convertDownSlide.setKeys([
      { frame: 0, value: 0 },
      { frame: 80, value: -.16 },
      { frame: 100, value: -.5 },
    ]);
    convertDownSlide.setEasingFunction(circleEase)
    const convertDownRotate = new Animation("ConvertDownRotate", "rotation.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    convertDownRotate.setKeys([
      { frame: 0, value: 0 },
      { frame: 80, value: Math.PI * 10 },
      { frame: 100, value: Math.PI * 9.9 },
    ]);
    convertDownRotate.setEasingFunction(circleEase)
    this.convertAnimation = new AnimationGroup("ConvertAnimation");
    this.convertAnimation.addTargetedAnimation(convertUpSlide, convertUp);
    this.convertAnimation.addTargetedAnimation(convertUpRotate, convertUp);
    this.convertAnimation.addTargetedAnimation(convertDownSlide, convertDown);
    this.convertAnimation.addTargetedAnimation(convertDownRotate, convertDown);

    // 初始化
    this.setSize(this.state.size)
    this.setAssemble(this.state.assemble)
    this.setRotate(this.state.rotate)
    this.setEnvironment(this.state.environment)

    this.setState({ loading: false })
  }

  openDebugLayer = () => this.scene!.debugLayer.show()
  setSize = (v: Size) => {
    switch (v) {
      case Size.BQ:
        this.convertDownBQ!.isVisible = true;
        this.convertUpNQ!.isVisible = false;
        this.convertDownNQ!.isVisible = false;
        this.convertUpHQ!.isVisible = false;
        this.convertDownHQ!.isVisible = false;
        break;
      case Size.NQ:
        this.convertDownBQ!.isVisible = false;
        this.convertUpNQ!.isVisible = true;
        this.convertDownNQ!.isVisible = true;
        this.convertUpHQ!.isVisible = false;
        this.convertDownHQ!.isVisible = false;
        break;
      case Size.HQ:
        this.convertDownBQ!.isVisible = false;
        this.convertUpNQ!.isVisible = false;
        this.convertDownNQ!.isVisible = false;
        this.convertUpHQ!.isVisible = true;
        this.convertDownHQ!.isVisible = true;
        break;
    }
  }
  setAssemble = (v: boolean) => {
    let fromFrame = null
    const animatables = this.convertAnimation!.animatables
    if (animatables.length)
      fromFrame = animatables[0].masterFrame
    if (v) {
      this.convertAnimation!.stop()
      this.convertAnimation!.normalize(fromFrame ?? 100, 0)
      this.convertAnimation!.play()
    } else {
      this.convertAnimation!.stop()
      this.convertAnimation!.normalize(fromFrame ?? 0, 100)
      this.convertAnimation!.play()
    }
  }
  setRotate = (v: boolean) => {
    this.camera!.useAutoRotationBehavior = v
  }
  setEnvironment = (v: boolean) => {
    this.skybox!.isVisible = v
  }

  render() {
    return (
      <div className="main">
        <Dialog
          open={this.state.loading!}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Waiting ...</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ display: 'flex', alignItems: 'center', gap: 16 }} >
              <CircularProgress />
              Please wait model is loading.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
        <div className='menu'>
          <div className='fill' />
          <FormControl sx={{ minWidth: 100 }} size="small">
            <InputLabel id="select-size">Size</InputLabel>
            <Select
              labelId="select-size"
              id="select-size"
              value={this.state.size}
              label="Size"
              onChange={event => this.setState({ size: event.target.value as Size })}
            >
              <MenuItem value={Size.BQ}>BQ</MenuItem>
              <MenuItem value={Size.NQ}>NQ</MenuItem>
              <MenuItem value={Size.HQ}>HQ</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel control={<Checkbox checked={this.state.assemble} onChange={(_, v) => this.setState({ assemble: v })} />} label="Assemble" />
          <FormControlLabel control={<Checkbox checked={this.state.rotate} onChange={(_, v) => this.setState({ rotate: v })} />} label="Rotate" />
          <FormControlLabel control={<Checkbox checked={this.state.environment} onChange={(_, v) => this.setState({ environment: v })} />} label="Environment" />
          {process.env.NODE_ENV !== 'production' ? <Button variant="contained" onClick={this.openDebugLayer}>Debug</Button> : null}
        </div>
        <div className="scene">
          <SceneComponent antialias adaptToDeviceRatio={false} onSceneReady={this.onSceneReady} />
        </div>
      </div>
    );
  }
}

export default App;
